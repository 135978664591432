<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
    </dialog-form-list>
  </div>
</template>

<script>

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const _that = this
    return {
      title: this.$t('accountPermissionManage.changeContactVisibleRange'),
      showDialogMultipleTree: false,
      defaultContent: [],
      renewalType: '',
      showRenewal:false,
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:允许创建工作流文件数量(个)
        {
          tag: 'el-select',
          label: this.$t('companyPermissionManage.workFlowFileLimitNum'),
          prop: 'workFlowFileLimitNum',
          tagProps: {
            options: [
              {
                label: this.$t('companyPermissionManage.unlimited'),
                value: -1,
              },
              {
                label: 1,
                value: 1,
              },
              {
                label: 2,
                value: 2,
              },
              {
                label: 3,
                value: 3,
              },
              {
                label: 4,
                value: 4,
              },
              {
                label: 5,
                value: 5,
              },
              {
                label: 6,
                value: 6,
              },
              {
                label: 7,
                value: 7,
              },
              {
                label: 8,
                value: 8,
              },
              {
                label: 9,
                value: 9,
              },
              {
                label: 10,
                value: 10,
              },
              {
                label: 11,
                value: 11,
              },
              {
                label: 12,
                value: 12,
              },
              {
                label: 13,
                value: 13,
              },
              {
                label: 14,
                value: 14,
              },
              {
                label: 15,
                value: 15,
              },
              {
                label: 16,
                value: 16,
              },
              {
                label: 17,
                value: 17,
              },
              {
                label: 18,
                value: 18,
              },
              {
                label: 19,
                value: 19,
              },
              {
                label: 20,
                value: 20,
              },
            ],
          },
        },
        // lang:允许创建任务数量(个)
        {
          tag: 'el-select',
          label: this.$t('companyPermissionManage.workFlowTaskLimitNum'),
          prop: 'workFlowTaskLimitNum',
          tagProps: {
            options: [
              {
                label: this.$t('companyPermissionManage.unlimited'),
                value: -1,
              },
              {
                label: 1,
                value: 1,
              },
              {
                label: 2,
                value: 2,
              },
              {
                label: 3,
                value: 3,
              },
              {
                label: 4,
                value: 4,
              },
              {
                label: 5,
                value: 5,
              },
              {
                label: 6,
                value: 6,
              },
              {
                label: 7,
                value: 7,
              },
              {
                label: 8,
                value: 8,
              },
              {
                label: 9,
                value: 9,
              },
              {
                label: 10,
                value: 10,
              },
              {
                label: 11,
                value: 11,
              },
              {
                label: 12,
                value: 12,
              },
              {
                label: 13,
                value: 13,
              },
              {
                label: 14,
                value: 14,
              },
              {
                label: 15,
                value: 15,
              },
              {
                label: 16,
                value: 16,
              },
              {
                label: 17,
                value: 17,
              },
              {
                label: 18,
                value: 18,
              },
              {
                label: 19,
                value: 19,
              },
              {
                label: 20,
                value: 20,
              },
            ],
          },
        },
        // lang:任务执行并发人数(个)
        {
          tag: 'el-select',
          label: this.$t('companyPermissionManage.workFlowExInTimeLimitNum'),
          prop: 'workFlowExInTimeLimitNum',
          tagProps: {
            options: [
              {
                label: this.$t('companyPermissionManage.unlimited'),
                value: -1,
              },
              {
                label: 1,
                value: 1,
              },
              {
                label: 2,
                value: 2,
              },
              {
                label: 3,
                value: 3,
              },
              {
                label: 4,
                value: 4,
              },
              {
                label: 5,
                value: 5,
              },
              {
                label: 6,
                value: 6,
              },
              {
                label: 7,
                value: 7,
              },
              {
                label: 8,
                value: 8,
              },
              {
                label: 9,
                value: 9,
              },
              {
                label: 10,
                value: 10,
              },
              {
                label: 11,
                value: 11,
              },
              {
                label: 12,
                value: 12,
              },
              {
                label: 13,
                value: 13,
              },
              {
                label: 14,
                value: 14,
              },
              {
                label: 15,
                value: 15,
              },
              {
                label: 16,
                value: 16,
              },
              {
                label: 17,
                value: 17,
              },
              {
                label: 18,
                value: 18,
              },
              {
                label: 19,
                value: 19,
              },
              {
                label: 20,
                value: 20,
              },
            ],
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.initLimitValue('workFlowFileLimitNum')
          this.initLimitValue('workFlowTaskLimitNum')
          this.initLimitValue('workFlowExInTimeLimitNum')
        }
      })
    },
    initLimitValue(type) {
      let aaa = -1
      if(this.tableRow.companyPermission === null) {
        aaa = -1
      } else if(this.tableRow.companyPermission
          && this.tableRow.companyPermission[type]) {
        aaa = this.tableRow.companyPermission[type]
      }
      this.formData[type] = aaa
    },
    confirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.updateCompanyPermission,
        data: {
          companyUid: this.tableRow.uid,
          ...this.formData
        },
        headers: {product_version: 'SAFETY_MANAGEMENT'}
      }).then(res => {
        this.$emit('closePage', 'confirm')
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
  },
}
</script>
